import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import * as routes from '../../shared/routes';
import Layout from '../Layout';
import './ContentPageLayout.scss';

const ContentPageLayout = ({ children, title }) => (
  <Layout>
    <div className="ContentPageLayout">
      <nav className="ContentPageLayout-breadcrumb">
        <Link className="Link" to={routes.HOME}>
          Inicio
        </Link>{' '}
        <span className="ContentPageLayout-arrow">></span>
        <a className="Link" href={typeof window !== `undefined` && window.location.href}>
          {title}
        </a>
      </nav>
      <div className="ContentPageLayout-wrapper">
        <h1 className="ContentPageLayout-title">{title}</h1>
        <div className="ContentPageLayout-content">{children}</div>
      </div>
    </div>
  </Layout>
);

ContentPageLayout.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
};

export default ContentPageLayout;
