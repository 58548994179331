import React from 'react';
import '../ContentPageLayout/ContentPageLayout.scss';
import './CookiesPolicy.scss';

const CookiesPolicy = () => (
  <div className="CookiesPolicy">
    <section className="ContentPageLayout-block">
      <h2 className="ContentPageLayout-subTitle">¿Qué es una cookie?</h2>
      <p className="ContentPageLayout-text">
        Una cookie es un fichero que se descarga en el ordenador o dispositivo móvil del usuario al
        acceder a determinados sitios web. Las cookies permiten, entre otras cosas, almacenar y
        recuperar información sobre el número de visitas, los hábitos de navegación de un usuario o
        de su equipo o dispositivo móvil y, dependiendo de la información que contengan y de la
        forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
      </p>
      <h2 className="ContentPageLayout-subTitle">¿Qué tipos de Cookies utiliza este sitio web?</h2>
      <p className="ContentPageLayout-text">
        Al navegar por esta página web se utilizan Cookies de sesión y Cookies persistentes que, a
        su vez, pueden ser Cookies propias o de terceros.
      </p>
      <p className="ContentPageLayout-text">
        Las Cookies de sesión son cookies diseñadas para recabar y almacenar datos mientras el
        usuario accede a una página web. Se suelen emplear para almacenar información que solo
        interesa conservar para la prestación del servicio solicitado por el usuario en una sola
        ocasión.
      </p>
      <p className="ContentPageLayout-text">
        Las Cookies persistentes son un tipo de cookies en el que los datos siguen almacenados en el
        terminal y a los que se puede acceder y tratar durante un periodo definido por el
        responsable de la cookie, y que puede ir de unos minutos a varios años.
      </p>
      <p className="ContentPageLayout-text">
        Cookies Propias: Las creadas o gestionadas por el titular del sitio web, con las siguientes
        finalidades:
      </p>
      <ul className="ContentPageLayout-list">
        <li className="ContentPageLayout-listItem">
          <strong className="ContentPageLayout-strong">Análisis</strong> Son aquéllas que permiten
          el seguimiento y análisis del comportamiento de los usuarios de la web, para la
          elaboración de perfiles de navegación, con el fin de introducir mejoras en función del
          análisis de los datos de uso sobre la página web.
        </li>
      </ul>
      <p className="ContentPageLayout-text">
        Cookies de Terceros: Las administradas por prestadores de servicios publicitarios, de
        personalización y de análisis ajenos al titular del sitio web.
      </p>
      <p className="ContentPageLayout-text">
        El tipo de cookies de terceros que utiliza nuestra página web y su finalidad son las
        siguientes:
      </p>
      <table className="ContentPageLayout-table Table Table--2ColSm">
        <thead className="Table-head">
          <tr className="Table-row">
            <th className="Table-headCell">Tipología</th>
            <th className="Table-headCell">Tercero</th>
            <th className="Table-headCell">Finalidad</th>
            <th className="Table-headCell">Más Información</th>
          </tr>
        </thead>
        <tbody className="Table-body">
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Analíticas
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              Google Analytics
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Obtener y analizar la información de la navegación del usuario por nuestro sitio web
              para mejorar nuestros servicios y realizar estadísticas
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a
                className="Link"
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                target="_blank"
              >
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Uso del chat
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              LiveChat Inc
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Mejorar la experiencia de navegación del usuario en el sitio web
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a className="Link" href="http://www.livechatinc.com/privacy-policy/" target="_blank">
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Publicitaria
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              Google Adwords
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Ofrecer contenido relevante a los visitantes del sitio web relacionados con su uso y
              navegación
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a
                className="Link"
                href="https://support.google.com/adwords/answer/2407785?hl=en"
                target="_blank"
              >
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Publicitaria
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              AdRoll
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Ofrecer contenido relevante a los visitantes del sitio web relacionados con su uso y
              navegación
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a className="Link" href="https://www.adroll.com/about/privacy" target="_blank">
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Publicitaria
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              Facebook
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Ofrecer contenido relevante a los visitantes del sitio web relacionados con su uso y
              navegación
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a
                className="Link"
                href="https://www.facebook.com/help/cookies/?ref=sitefooter"
                target="_blank"
              >
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Publicitaria
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              Bing Ads
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Ofrecer contenido relevante a los visitantes del sitio web relacionados con su uso y
              navegación
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a
                className="Link"
                href="https://privacy.microsoft.com/es-es/privacystatement"
                target="_blank"
              >
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Publicitaria
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              Criteo
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Ofrecer contenido relevante a los visitantes del sitio web relacionados con su uso y
              navegación
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a className="Link" href="http://www.criteo.com/es/privacy/" target="_blank">
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Publicitaria
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              PuschCrew
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Ofrecer contenido relevante a los visitantes del sitio web relacionados con su uso y
              navegación
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a className="Link" href="https://pushcrew.com/privacy-policy/" target="_blank">
                Link
              </a>
            </td>
          </tr>
          <tr className="Table-row">
            <td className="Table-bodyCell" data-th="Tipología">
              Publicitaria
            </td>
            <td className="Table-bodyCell" data-th="Tercero">
              Hotjar
            </td>
            <td className="Table-bodyCell" data-th="Finalidad">
              Ofrecer contenido relevante a los visitantes del sitio web relacionados con su uso y
              navegación
            </td>
            <td className="Table-bodyCell" data-th="Más Información">
              <a
                className="Link"
                href="https://docs.hotjar.com/v1.0/docs/data-safety-and-security"
                target="_blank"
              >
                Link
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="ContentPageLayout-subTitle">
        ¿Cómo puedo desactivar o eliminar las cookies que utiliza este Sitio Web?
      </h2>
      <p className="ContentPageLayout-text">
        El usuario puede permitir, bloquear o eliminar las cookies instaladas en su equipo o
        dispositivo móvil mediante la configuración de las opciones del navegador instalado en su
        ordenador o dispositivo móvil. En caso de que el usuario no permita la instalación de
        cookies, es posible que no pueda acceder a alguna de las secciones del Sitio Web.
      </p>
      <p className="ContentPageLayout-text">
        Para el caso de dispositivos móviles, para saber cómo proceder en el navegador de su
        teléfono móvil, deberá consultar el manual de su terminal.
      </p>
      <p className="ContentPageLayout-text">Para más información sobre el navegador Firefox: </p>
      <p className="ContentPageLayout-text">
        <a
          className="Link"
          href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
          target="_blank"
        >
          https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
        </a>
      </p>
      <p className="ContentPageLayout-text">Para más información sobre el navegador Chrome: </p>
      <p className="ContentPageLayout-text">
        <a
          className="Link"
          href="https://support.google.com/chrome/answer/95647?hl=es"
          target="_blank"
        >
          https://support.google.com/chrome/answer/95647?hl=es
        </a>
      </p>
      <p className="ContentPageLayout-text">Para más información sobre el navegador Explorer: </p>
      <p className="ContentPageLayout-text">
        <a
          className="Link"
          href="https://support.microsoft.com/en-us/products/windows"
          target="_blank"
        >
          https://support.microsoft.com/en-us/products/windows
        </a>
      </p>
      <p className="ContentPageLayout-text">Para más información sobre el navegador Safari: </p>
      <p className="ContentPageLayout-text">
        <a className="Link" href="https://support.apple.com/es-es/HT201265" target="_blank">
          https://support.apple.com/es-es/HT201265
        </a>
      </p>
      <p className="ContentPageLayout-text">Para más información sobre el navegador Opera: </p>
      <p className="ContentPageLayout-text">
        <a
          className="Link"
          href="http://help.opera.com/Windows/11.50/es-ES/cookies.html"
          target="_blank"
        >
          http://help.opera.com/Windows/11.50/es-ES/cookies.html
        </a>
      </p>
      <p className="ContentPageLayout-text">
        El usuario puede revocar su consentimiento para el uso de cookies en su navegador (opt-out)
        a través de las opciones de la configuración del navegador o a través de los enlaces de
        terceros como:
      </p>
      <p className="ContentPageLayout-text">
        Google Analytics:{' '}
        <a className="Link" href="https://tools.google.com/dlpage/gaoptout?hl=None" target="_blank">
          https://tools.google.com/dlpage/gaoptout?hl=None
        </a>
      </p>
      <p className="ContentPageLayout-text">
        El Usuario debe tener en cuenta que algunas características de los contenidos de la presente
        página web solo están disponibles si se permite la instalación de Cookies en su navegador.
        Si decide no aceptar o bloquear determinadas Cookies (en función de su finalidad), puede que
        esto afecte, total o parcialmente, al funcionamiento normal de la página web o que impida el
        acceso a algunos servicios de la misma.
      </p>
      <h2 className="ContentPageLayout-subTitle">
        Actualizaciones y cambios en la política de cookies.
      </h2>
      <p className="ContentPageLayout-text">
        El titular del sitio web puede modificar esta Política de Cookies en función de nuevas
        exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las
        instrucciones dictadas por la Agencia Española de Protección de Datos.
      </p>
      <p className="ContentPageLayout-text">
        Cuando se produzcan cambios significativos en esta Política de Cookies se comunicará al
        usuario bien mediante un aviso informativo en la página web del editor bien a través de
        correo electrónico para los usuarios registrados.
      </p>
      <p className="ContentPageLayout-text">
        Si desea más información sobre qué uso hacemos de las Cookies, puede enviarnos un e-mail a{' '}
        <a className="Link" title="info@quebueno.es" href="mailto:info@quebueno.es">
          info@quebueno.es
        </a>
        .
      </p>
      <p className="ContentPageLayout-text">
        El titular del sitio web no se hace responsable del contenido y veracidad de los links a
        páginas web de terceros y/o demás documentos de terceros incluidos y/o remitidos a través de
        esta política de cookies.
      </p>
    </section>
  </div>
);

export default CookiesPolicy;
